exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-csapataink-js": () => import("./../../../src/pages/csapataink.js" /* webpackChunkName: "component---src-pages-csapataink-js" */),
  "component---src-pages-hirek-js": () => import("./../../../src/pages/hirek.js" /* webpackChunkName: "component---src-pages-hirek-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informaciok-js": () => import("./../../../src/pages/informaciok.js" /* webpackChunkName: "component---src-pages-informaciok-js" */),
  "component---src-pages-jegcsarnok-js": () => import("./../../../src/pages/jegcsarnok.js" /* webpackChunkName: "component---src-pages-jegcsarnok-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-merkozes-js": () => import("./../../../src/pages/merkozes.js" /* webpackChunkName: "component---src-pages-merkozes-js" */),
  "component---src-pages-rolunk-js": () => import("./../../../src/pages/rolunk.js" /* webpackChunkName: "component---src-pages-rolunk-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

